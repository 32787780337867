import React from 'react';
import PropTypes from 'prop-types';
import { EVENT_KEYS } from '../../constants';
import './FlightCarouselItem.scss';

const DEFAULT_CLASS = 'flight-carousel-item';

const FlightCarouselItem = (props) => {
  const {
    className, onClick, children, onFocus,
  } = props;

  let mainClass = DEFAULT_CLASS;
  mainClass += className ? ` ${className}` : '';

  const handleOnKeyDown = (event) => {
    if (event.key === EVENT_KEYS.ENTER) {
      if (onClick) onClick();
    }
  };

  return (
    <div
      className={mainClass}
      onClick={onClick}
      onFocus={onFocus}
      onKeyDown={handleOnKeyDown}
      tabIndex={0}
      role="button"
    >
      {children}
    </div>
  );
};

FlightCarouselItem.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  children: PropTypes.node,
};

FlightCarouselItem.defaultProps = {
  className: '',
  onClick: () => undefined,
  onFocus: () => undefined,
  children: null,
};

export default FlightCarouselItem;
