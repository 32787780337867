import React from 'react';
import PropTypes from 'prop-types';
import { CHECKBOX_STATES } from '../constants';
import getIcon from '../utils/getIcon';
import FlightCheckbox from '../flight-checkbox/FlightCheckbox';
import FlightTableRow from './flight-table-row/FlightTableRow';
import FlightTableHeader from './flight-table-header/FlightTableHeader';
import FlightTableLoading from './flight-table-loading/FlightTableLoading';
import FlightTablePagination from './flight-table-pagination/FlightTablePagination';
import './FlightTable.scss';

const DEFAULT_CLASS = 'flight-table';
const SORT_ICON_PROPS = {
  className: 'flight-table-header__sort-icon',
};

const SORT_ICON_TOGGLE_MAP = {
  desc: getIcon('baselineArrowDown', SORT_ICON_PROPS),
  asc: getIcon('baselineArrowUp', SORT_ICON_PROPS),
};

const FlightTable = (props) => {
  const {
    tableHeaders, tableData, isLoading, loadingRowNumber, className,
    emptyState, hasError, errorState, selectedDataKey, allowRowSelect,
    allowMultiSelect, isShowHeader, headerTooltipDelay,
    hasPaginationAfterTable, hasPaginationBeforeTable, paginationProps,
    handleHeaderSort, sortByKey, sortOrder, handleDataSelect, handleSelectAll,
  } = props;

  const handleOnDataSelect = (item) => {
    if (allowRowSelect && handleDataSelect) handleDataSelect(item);
  };

  const handleToggleHeaderCheckbox = () => {
    // whether the action is to select all or unselect all
    let newSelectedDataKey = new Set();
    if (allowMultiSelect && selectedDataKey.size !== tableData.length) {
      newSelectedDataKey = new Set(tableData.map((item) => item.key));
    }
    if (handleSelectAll) handleSelectAll(newSelectedDataKey);
  };

  // handle changes in headerCheckboxState from single row select/deselect
  let headerCheckboxState = CHECKBOX_STATES.INDETERMINATE;
  if (allowMultiSelect && allowRowSelect) {
    if (selectedDataKey.size === tableData.length) {
      headerCheckboxState = CHECKBOX_STATES.SELECTED;
    } else if (selectedDataKey.size === 0) {
      headerCheckboxState = CHECKBOX_STATES.UNSELECTED;
    }
  }
  const tableHeadersComputed = (allowMultiSelect && allowRowSelect) ? [{
    name: (
      <FlightCheckbox
        checkState={headerCheckboxState}
        onSelect={handleToggleHeaderCheckbox}
      />),
    key: 'checkbox',
    isVisible: true,
    hideTooltip: true,
  }].concat(tableHeaders) : tableHeaders;

  let tableStateElement = null;
  if (hasError && !tableData.length) {
    tableStateElement = errorState;
  } else if (!tableData.length && !isLoading) {
    tableStateElement = emptyState;
  }
  let tableRows = null;
  if (isLoading) {
    tableRows = (
      <FlightTableLoading
        rowNumber={loadingRowNumber}
        colNumber={tableHeadersComputed.filter(
          (header) => header.isVisible,
        ).length}
      />
    );
  } else if (tableData.length > 0) {
    const tableDataComputed = (allowRowSelect && allowMultiSelect
      ? tableData.map((item) => ({
        checkbox: (
          <FlightCheckbox
            checkState={(selectedDataKey.has(item.key)
              ? CHECKBOX_STATES.SELECTED : CHECKBOX_STATES.UNSELECTED)}
            onSelect={() => undefined}
          />
        ),
        ...item,
      }))
      : tableData);

    tableRows = tableDataComputed.map((item) => (
      <FlightTableRow
        item={item}
        columns={tableHeadersComputed.filter(
          (header) => header.isVisible,
        ).map((col) => col.key)}
        key={item.key}
        allowRowSelect={allowRowSelect}
        onClick={() => handleOnDataSelect(item)}
        selected={(allowMultiSelect ? selectedDataKey.has(item.key)
          : selectedDataKey === item.key)}
      />
    ));
  }

  return (
    <div className={`${DEFAULT_CLASS}-wrapper ${className}`}>
      {hasPaginationBeforeTable && (
        <FlightTablePagination
          className="top-pagination"
          {...paginationProps}
        />
      )}
      <table className={`${DEFAULT_CLASS}${allowMultiSelect && allowRowSelect
        ? ` ${DEFAULT_CLASS}--multi-select` : ''}`}
      >
        {isShowHeader && (
          <thead>
            <tr>
              {
                tableHeadersComputed.map((header) => (
                  <FlightTableHeader
                    name={header.name}
                    icon={SORT_ICON_TOGGLE_MAP[sortOrder]}
                    isSortable={header.isSortable}
                    onClick={() => handleHeaderSort(header)}
                    isVisible={header.isVisible}
                    key={header.key}
                    hideTooltip={header.hideTooltip}
                    selected={sortByKey === header.key}
                    tooltipDelay={headerTooltipDelay}
                  />
                ))
              }
            </tr>
          </thead>
        )}
        <tbody>{tableRows}</tbody>
      </table>
      {tableStateElement}
      {hasPaginationAfterTable && (
        <FlightTablePagination
          className="bottom-pagination"
          {...paginationProps}
        />
      )}
    </div>
  );
};

FlightTable.propTypes = {
  className: PropTypes.string,
  tableHeaders: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    key: PropTypes.string,
    isVisible: PropTypes.bool,
    isSortable: PropTypes.bool,
    hideTooltip: PropTypes.bool,
    tooltipDelay: PropTypes.number,
  })).isRequired,
  tableData: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  })),
  selectedDataKey: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Set),
  ]),
  isLoading: PropTypes.bool,
  loadingRowNumber: PropTypes.number,
  hasError: PropTypes.bool,
  errorState: PropTypes.element,
  emptyState: PropTypes.element,
  allowRowSelect: PropTypes.bool,
  handleHeaderSort: PropTypes.func,
  sortByKey: PropTypes.string,
  sortOrder: PropTypes.string,
  allowMultiSelect: PropTypes.bool,
  handleDataSelect: PropTypes.func,
  handleSelectAll: PropTypes.func,
  isShowHeader: PropTypes.bool,
  headerTooltipDelay: PropTypes.number,
  hasPaginationAfterTable: PropTypes.bool,
  hasPaginationBeforeTable: PropTypes.bool,
  paginationProps: PropTypes.shape({
    totalPageNumber: PropTypes.number,
    currentPageNumber: PropTypes.number,
    rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
    currentRowsPerPage: PropTypes.number,
    handlePageChange: PropTypes.func,
    handleRowsPerPageChange: PropTypes.func,
  }),
};

FlightTable.defaultProps = {
  className: '',
  tableData: [],
  selectedDataKey: null,
  isLoading: false,
  loadingRowNumber: 3,
  hasError: false,
  errorState: null,
  emptyState: null,
  allowRowSelect: false,
  handleHeaderSort: () => undefined,
  sortByKey: null,
  sortOrder: null,
  allowMultiSelect: false,
  handleDataSelect: () => undefined,
  handleSelectAll: () => undefined,
  isShowHeader: true,
  headerTooltipDelay: 400,
  hasPaginationAfterTable: false,
  hasPaginationBeforeTable: true,
  paginationProps: {
    totalPageNumber: 1,
    currentPageNumber: 1,
    rowsPerPageOptions: [10, 20, 40, 60],
    currentRowsPerPage: 20,
    handlePageChange: () => undefined,
    handleRowsPerPageChange: () => undefined,
  },
};

export default FlightTable;
